@charset "utf-8";



// Our variables
$background-color: rgb(250,250,250);
$text-color:       #000;

// Stuff
html {
    height: 100%;
    overflow: hidden;  
}

body {
    background: $background-color;
    height: 100%;
}

#top {
    float: left; 
    width: 1px;
    height: 50%;
    margin-bottom: -12em;
}

#witzig {
	clear: left;
	text-align: center;
    font-size: 400%;
    font-weight: bold;
}

#nichtwitzig {
	clear: left;
	text-align: center;
	max-width: 600px;
	max-height: 66%;
	margin: 0 auto;
	overflow: auto;

	ul {
		text-align: left;
	}
}

#nav {
    width: 90%;
    text-align: center;
    margin: 0 auto;
    border-bottom: 1px solid $text-color;
    padding-bottom: 5px;
}

.spacing {
    display: inline-block;
    width: 2em;
}
a {
	color: $text-color;
	text-decoration: none;
}
a:hover {
	color: $text-color;
	text-decoration: underline;
}
